<template>
  <div>

    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="$t('Nome')"
          >
            <b-form-input
              v-model="nome"
              placeholder=""
              autocomplete="off"
              :class="errors.length > 0 ? 'is-invalid':null"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Apelido')"
        >
          <b-form-input
            v-model="apelido"
            placeholder=""
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          :rules="{numberVueTelInput: numberPhoneValidate }"
        >
          <b-form-group
            :label="$t('Telefone')"
          >
            <vue-tel-input
              ref="phone"
              v-model="telefone"
              class="form-control"
              inputmode="tel"
              :mode="configPhone.mode"
              :valid-characters-only="configPhone.validCharactersOnly"
              :auto-format="configPhone.autoFormat"
              :default-country="configPhone.defaultCountry"
              :auto-default-country="configPhone.autoDefaultCountry"
              :input-options="configPhone.inputOptions"
              :dropdown-options="configPhone.dropdownOptions"
              :class="errors.length > 0 ? 'is-invalid':null"
              @input="checkValidateNumberPhone"
              @open="onDropdownOpenTelInput"
              @keydown.native.tab.prevent="$event.target.blur()"
              @blur.native.capture.prevent="inputValidateNumberPhone($refs.phone)"
              @hook:mounted="loadDataFormPhone"
            >
              <template slot="arrow-icon">
                {{ '\xa0' }}
              </template>
              <template slot="icon-right">
                {{ '\xa0' }}
              </template>
            </vue-tel-input>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="email"
        >
          <b-form-group
            :label="$t('Email')"
          >
            <b-form-input
              v-model="email"
              placeholder=""
              autocomplete="off"
              inputmode="email"
              :class="errors.length > 0 ? 'is-invalid':null"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="$t('Género')"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <v-select
              v-model="genero"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="load_data_geral.generos"
              append-to-body
              :calculate-position="withPopper"
              label="txt"
              item-text="txt"
              item-value="id"
              @input="getAllGrausParentesco"
            >
              <template #option="{ txt }">
                {{ txt }}
              </template>
              <div slot="no-options">
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            :label="$t('Grau parentesco')"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <v-select
              ref="grauParentesco"
              v-model="grau"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="grausParentesco"
              append-to-body
              :calculate-position="withPopper"
              :loading="loaderGrau"
              label="sw024s02"
              item-text="sw024s02"
              item-value="sw024s01"
              :placeholder="((genero === null) ? $t('Escolha um género') : '')"
            >
              <template #option="{ sw024s02 }">
                {{ sw024s02 }}
              </template>
              <div
                slot="no-options"
              >
                {{ $t('Sem resultados') }}
              </div>
            </v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Data de aniversário')"
        >
          <date-picker
            v-model="data_aniversario"
            value-type="format"
            input-class="form-control"
            type="date"
            :editable="false"
            :clearable="true"
            :lang="langCalendar"
            :append-to-body="true"
            prefix-class="sw"
            :disabled-date="disabledMaxDateBirtday"
          >
            <template #icon-clear>
              <v-select-deselect />
            </template>
          </date-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          :rules="(($store.getters['auth/countryApp'] == 'pt') ? 'nif': '')"
        >
          <b-form-group
            :label="(($store.getters['auth/countryApp'] == 'es') ? $t('DNI') : $t('NIF'))"
          >
            <b-form-input
              v-model="nif"
              v-mask="tokenMaskNIF"
              placeholder=""
              autocomplete="off"
              :class="errors.length > 0 ? 'is-invalid':null"
              :type="(($store.getters['auth/countryApp'] == 'pt') ? 'number': 'text')"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row
      v-if="$store.getters['auth/countryApp']==='pt'"
    >
      <b-col md="12">
        <b-form-group
          :label="$t('BI / CC')"
        >
          <b-form-input
            v-model="bi_cc"
            placeholder=""
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="$store.getters['auth/countryApp']==='pt'"
    >
      <b-col md="12">
        <b-form-group
          :label="$t('Validade do BI / CC')"
        >
          <date-picker
            v-model="data_validade_bi_cc"
            value-type="format"
            input-class="form-control"
            type="date"
            :editable="false"
            :clearable="true"
            :lang="langCalendar"
            :append-to-body="true"
            prefix-class="sw"
          >
            <template #icon-clear>
              <v-select-deselect />
            </template>
          </date-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="$store.getters['auth/countryApp']==='es'"
    >
      <b-col md="12">
        <b-form-group
          :label="$t('NIE')"
        >
          <b-form-input
            v-model="nie"
            placeholder=""
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormGroup, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import modelSW025 from '@store-modules/crm/leads/data'
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { numberVueTelInput } from '@validations-custom'
import { configPhone } from '@core-custom/utils/phone'
import
{
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js/max'
import { mixFormGeral } from '../../../mixins'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormInput,
    ValidationProvider,
    VueTelInput: oVueTelInput,
    DatePicker: oDatepicker,
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    mask,
  },
  mixins: [mixFormGeral],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDateBirtday = new Date(today)
    maxDateBirtday.setFullYear(maxDateBirtday.getFullYear() - 18)

    return {
      id: null,
      numberVueTelInput,
      nome: null,
      apelido: null,
      telefone: null,
      email: null,
      genero: null,
      grau: null,
      data_aniversario: null,
      nif: null,
      bi_cc: null,
      data_validade_bi_cc: null,
      nie: null,
      grausParentesco: [],
      loaderGrau: false,
      configPhone,
      tokenMaskNIF: {
        mask: '#########',
        tokens: {
          '#': {
            pattern: /\d/,
          },
        },
      },
      maxDateBirtday,
      langCalendar: {},
    }
  },
  computed:
      {
        ...mapGetters('crm_leads_form', ['form_data', 'load_data_geral']),
        numberPhoneValidate() {
          try {
            if (this.telefone) {
              return this.$refs.phone.phoneObject
            }
          } catch (err) {
            //
          }

          return null
        },
      },
  async created() {
    await this.$nextTick(() => {
      this.loadDataForm()
    })
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    getAllGrausParentesco(row) {
      this.grausParentesco = []
      this.grau = null
      try {
        if (row.id) {
          this.loaderGrau = true
          this.$store.dispatch('sw025/getAllGrausParentesco', row.id).then(res => {
            this.loaderGrau = false
            this.grausParentesco = res
          }).catch(error => {
            this.loaderGrau = false
            this.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    onDropdownOpenTelInput() {
      this.$nextTick(() => {
        const widthElInputTel = this.$refs.phone.$el.offsetWidth
        const el = this.$refs.phone.$refs.list
        el.querySelector('.vti__search_box').placeholder = this.$t('Digite o nome')
        el.querySelector('.vti__search_box').addEventListener('keydown', evt => {
          const charCode = (evt.which) ? evt.which : window.event.keyCode

          if (charCode === 187) {
            evt.preventDefault()
          }
        })

        el.style.width = `${widthElInputTel}px`
      })
    },
    checkValidateNumberPhone($event, phoneObject) {
      this.configPhone.inputOptions.maxlength = 25
      try {
        const phoneNumber = parsePhoneNumber($event, phoneObject?.countryCode || '')
        if (phoneNumber) {
          const sIsPossiblePhoneNumber = isPossiblePhoneNumber(`${$event}0`) // Insert +1 character to simulate
          const sValidatePhoneNumberLength = validatePhoneNumberLength(`${$event}0`) // Insert +1 character to simulate

          if (sValidatePhoneNumberLength === 'TOO_LONG') {
            if (sIsPossiblePhoneNumber === false) {
              this.configPhone.inputOptions.maxlength = $event.length ?? 0
            }
          }
        }
      } catch (error) {
        //
      }
    },
    inputValidateNumberPhone(obj) {
      if (obj?.phoneObject?.number !== undefined && obj.phoneObject.number !== '') {
        try {
          const phoneNumber = parsePhoneNumber(obj.phoneObject.number, obj.phoneObject?.countryCode || '')
          if (phoneNumber) {
            const sIsValidPhoneNumber = phoneNumber.isValid()
            if (sIsValidPhoneNumber === false) {
              this.$nextTick(() => {
                this.telefone = ''
              })
            } else {
              this.$nextTick(() => {
                this.telefone = phoneNumber.formatInternational()
              })
            }
          }
        } catch (error) {
          //
        }
      } else {
        this.$nextTick(() => {
          this.telefone = ''
        })
      }
    },
    clearForm() {
      this.nome = null
      this.apelido = null
      this.telefone = ''
      this.email = null
      this.genero = null
      this.grau = null
      this.data_aniversario = null
      this.nif = null
      this.bi_cc = null
      this.data_validade_bi_cc = null
      this.nie = null
      this.grausParentesco = []
      this.configPhone.inputOptions.maxlength = 25
    },
    getDataSaveForm() {
      return new Promise(resolve => {
        resolve({
          id: this.id,
          nome: this.nome,
          apelido: this.apelido,
          telefone: this.telefone,
          email: this.email,
          genero: this.genero.id,
          grau: this.grau.sw024s01,
          data_aniversario: this.data_aniversario,
          nif: this.nif,
          bi_cc: this.bi_cc,
          data_validade_bi_cc: this.data_validade_bi_cc,
          nie: this.nie,
        })
      })
    },
    async loadDataForm() {
      try {
        if (('sw029s01' in this.data)) {
          this.id = this.data.sw029s01
          this.nome = this.data.sw029s05
          this.apelido = this.data.sw029s06
          this.email = this.data.sw029s10
          this.genero = this.load_data_geral.generos.find(o => o.id === this.data.sw029s07)
          this.grau = null
          this.data_aniversario = this.data.sw029s09
          this.nif = this.data.sw029s18
          this.bi_cc = this.data.sw029s19
          this.data_validade_bi_cc = this.data.sw029s20
          this.nie = this.data.sw029s23

          if (this.genero.id) {
            this.$store.dispatch('sw025/getAllGrausParentesco', this.genero.id).then(res => {
              this.grausParentesco = res
              this.$nextTick(() => {
                this.grau = this.grausParentesco.find(o => o.sw024s01 === this.data.sw029s04)
              })
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }
      } catch (err) {
        //
      }
    },
    loadDataFormPhone() {
      try {
        if (('sw029s01' in this.data)) {
          this.telefone = this.data.sw029s08
        }
      } catch (err) {
        //
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    disabledMaxDateBirtday(date) {
      return date > this.maxDateBirtday
    },
  },
  setup() {
    const NAMESPACE_SW025_FORM_DATA = 'sw025'

    if (!store.hasModule(NAMESPACE_SW025_FORM_DATA)) {
      store.registerModule(NAMESPACE_SW025_FORM_DATA, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM_DATA)) store.unregisterModule(NAMESPACE_SW025_FORM_DATA)
      })
    }

    return {
      NAMESPACE_SW025_FORM_DATA,
    }
  },
}
</script>

<style lang="scss">
.vti__search_box {
  width: 100% !important;
}
</style>
